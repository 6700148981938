import React, { useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
} from "reactstrap";
import Layout from "./layout/Layout";
import Logo from "../assets/images/logo-alt_new.svg";
import superior from "../assets/images/superior/superior.jpg";
import wellness from "../assets/images/wellness.JPG";
import restaurant from "../assets/images/restaurant_normal.jpeg";
import standard from "../assets/images/standard/standard3.jpg";
import deluxe from "../assets/images/deluxe/_30A5993.jpg";
import Germitec from "../assets/images/germitec-min.jpg";
import { ApplicationContext } from "../providers/ApplicationProvider";
import "./styles/style.css";
import "./styles/typography.css";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

const Home = () => {
  const [{ lang }] = useContext(ApplicationContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Wellness Hotel Edelweiss nabízí moderní a útulné prostředí pod Ještědem v Liberci. Ideální pro odpočinek po náročných pracovních nebo sportovních dnech. Užijte si pohodlné pokoje, wellness a poctivé české jídlo."
        />
        <title>Wellness Hotel Edelweiss | Hotel pod Ještědem, Liberec</title>
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Hotel pod Ještědem, Liberec"
        />
        <link rel="canonical" href="https://www.hoteledelweiss.cz"></link>
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Hotel pod Ještědem, Liberec"
        />
        <meta
          itemprop="description"
          content="Nabízíme našim hostům přímo pod Ještědem moderní, přesto útulné prostředí pro každého, kdo potřebuje oddych po náročných pracovních nebo sportovních dnech. Zatímco hory za okny nabídnou v zimě lyžování a v létě turistiku i další aktivity, Wellness hotel Edelweiss bude vaším místem odpočinku v pohodlných pokojích s nabídkou wellness a poctivého českého jídla. "
        />
        <meta
          itemprop="image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta property="og:url" content="https://hoteledelweiss.cz" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Wellness Hotel Edelweiss | Hotel pod Ještědem, Liberec"
        />
        <meta
          property="og:description"
          content="Nabízíme našim hostům přímo pod Ještědem moderní, přesto útulné prostředí pro každého, kdo potřebuje oddych po náročných pracovních nebo sportovních dnech. Zatímco hory za okny nabídnou v zimě lyžování a v létě turistiku i další aktivity, Wellness hotel Edelweiss bude vaším místem odpočinku v pohodlných pokojích s nabídkou wellness a poctivého českého jídla. "
        />
        <meta
          property="og:image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Wellness Hotel Edelweiss | Hotel pod Ještědem, Liberec"
        />
        <meta
          name="twitter:description"
          content="Nabízíme našim hostům přímo pod Ještědem moderní, přesto útulné prostředí pro každého, kdo potřebuje oddych po náročných pracovních nebo sportovních dnech. Zatímco hory za okny nabídnou v zimě lyžování a v létě turistiku i další aktivity, Wellness hotel Edelweiss bude vaším místem odpočinku v pohodlných pokojích s nabídkou wellness a poctivého českého jídla. "
        />
        <meta
          name="twitter:image"
          content="https://hoteledelweiss.cz/static/media/superior(1).42c1dfc214d0fa7843c1.jpg"
        />

        <meta
          name="description"
          content="Nabízíme našim hostům přímo pod Ještědem moderní, přesto útulné prostředí pro každého, kdo potřebuje oddych po náročných pracovních nebo sportovních dnech. Zatímco hory za okny nabídnou v zimě lyžování a v létě turistiku i další aktivity, Wellness hotel Edelweiss bude vaším místem odpočinku v pohodlných pokojích s nabídkou wellness a poctivého českého jídla. Hotel Liberec. Wellness Hotel Edelweiss."
        />
        <meta
          itemprop="name"
          content="Wellness Hotel Edelweiss | Hotel pod Ještědem, Liberec"
        />
        <meta
          itemprop="description"
          content="Nabízíme našim hostům přímo pod Ještědem moderní, přesto útulné prostředí pro každého, kdo potřebuje oddych po náročných pracovních nebo sportovních dnech. Zatímco hory za okny nabídnou v zimě lyžování a v létě turistiku i další aktivity, Wellness hotel Edelweiss bude vaším místem odpočinku v pohodlných pokojích s nabídkou wellness a poctivého českého jídla. "
        />
      </Helmet>
      <Fade>
        <Layout>
          <div className="showcase">
            <div className="showcase-content">
              <img
                src={Logo}
                className="intro-logo"
                alt="Wellness Hotel Edelweiss Liberec"
              />
              <div className="showcase-arrow">
                <a href="#welcome">
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </a>
              </div>
            </div>
          </div>
          <Container fluid className="welcome">
            <span></span>
            <div className="container-lg">
              <a class="anchor" id="welcome"></a>
              <div className="welcome-text">
                <Row className="mt-md-5 mt-3 mb-md-5">
                  <Col lg={7} className="p-0">
                    <h1>
                      {lang === "cs"
                        ? "VÍTEJTE V MÍSTĚ RELAXACE A POHODY, V HOTELU EDELWEISS"
                        : lang === "en"
                        ? "WELCOME TO A PLACE OF RELAXATION AND WELL-BEING, THE EDELWEISS HOTEL"
                        : "HERZLICH WILLKOMMEN AN EINEM ORT DER ENTSPANNUNG UND WOHLBEFINDEN, DEM EDELWEISS HOTEL"}
                    </h1>
                    {lang === "cs" ? (
                      <p>
                        Nabízíme našim hostům přímo pod Ještědem v Liberci
                        moderní, přesto útulné prostředí pro každého, kdo
                        potřebuje oddych po náročných pracovních nebo
                        sportovních dnech. Zatímco hory za okny nabídnou
                        v&nbsp;zimě lyžování a&nbsp;v&nbsp;létě turistiku
                        i&nbsp;další aktivity, Wellness hotel Edelweiss bude
                        vaším místem odpočinku v&nbsp;pohodlných pokojích
                        s&nbsp;nabídkou wellness a&nbsp;poctivého českého jídla.
                        Vyruší vás jen zpěv ptáků a&nbsp;touha jít se nadechnout
                        čerstvého vzduchu. Těšíme se na vás.
                      </p>
                    ) : lang === "en" ? (
                      <p>
                        We offer our guests a&nbsp;modern, yet cozy environment
                        right below Ještěd for anyone who needs a&nbsp;rest
                        after a&nbsp;hard day's work or sports. While
                        the&nbsp;mountains outside the&nbsp;windows offer skiing
                        in&nbsp;the&nbsp;winter and&nbsp;hiking and&nbsp;other
                        activities in&nbsp;the&nbsp;summer, the&nbsp;Wellness
                        Hotel Edelweiss will be your place of rest
                        in&nbsp;comfortable rooms with an&nbsp;offer of wellness
                        and&nbsp;honest Czech food. You will only be disturbed
                        by the&nbsp;singing of birds and&nbsp;the&nbsp;desire to
                        go and&nbsp;breathe fresh air. We look forward to you.
                      </p>
                    ) : (
                      <p>
                        Wir bieten unseren Gästen ein modernes und dennoch
                        gemütliches Ambiente direkt unter Ještěd für alle, die
                        sich nach einem anstrengenden Arbeits- oder Sporttag
                        erholen müssen. Während die Berge vor den Fenstern im
                        Winter Skifahren und im Sommer Wandern und andere
                        Aktivitäten bieten, ist das Wellnesshotel Edelweiss Ihr
                        Ort der Ruhe in komfortablen Zimmern, mit einem Angebot
                        an Wellness und ehrlicher tschechischer Küche. Sie
                        werden nur vom Vogelgezwitscher und dem Wunsch, frische
                        Luft zu schnappen, gestört. Wir freuen uns auf Dich.
                      </p>
                    )}
                  </Col>
                  <Col lg={1} className="p-0"></Col>
                  <Col lg={4} className="p-0">
                    {lang === "cs" ? (
                      <h3>Příběh Wellness hotelu Edelweiss</h3>
                    ) : lang === "en" ? (
                      <h3>The story of the Wellness Hotel Edelweiss</h3>
                    ) : (
                      <h3>Die Geschichte des Wellnesshotels Edelweiss</h3>
                    )}
                    {lang === "cs" ? (
                      <p>
                        Náš hotel jsme pojmenovali po horské květině Protěž
                        alpská, která odolává ve vysokých horách mrazu
                        i&nbsp;suchu. Přestože v&nbsp;okolí Ještědu neroste,
                        inspirovala nás svojí krásou a&nbsp;houževnatostí. Podle
                        lidové tradice je darování protěže příslibem oddanosti.
                        Naším mottem tedy je připravit našim hostům místo, kde
                        si odpočinou a&nbsp;načerpají síly.
                      </p>
                    ) : lang === "en" ? (
                      <p>
                        We named our hotel after the&nbsp;mountain flower
                        Abruzzo Edelweiss, which resists frost and&nbsp;drought
                        in&nbsp;the&nbsp;high mountains. Although it does not
                        grow near Ještěd, it inspired us with&nbsp;its beauty
                        and&nbsp;tenacity. According to folk tradition, giving
                        a&nbsp;gift is a&nbsp;pledge of devotion. Therefore, our
                        motto is to prepare a&nbsp;place for our guests where
                        they can relax and recharge their batteries.
                      </p>
                    ) : (
                      <p>
                        Wir haben unser Hotel nach der Bergblume Edelweiß
                        benannt, die im Hochgebirge Frost und Trockenheit
                        widersteht. Obwohl er nicht in der Nähe von Ještěd
                        wächst, hat er uns mit seiner Schönheit und Zähigkeit
                        inspiriert. Nach der Volkstradition ist das Schenken ein
                        Versprechen der Hingabe. Daher ist es unser Motto,
                        unseren Gästen einen Ort zu bereiten, an dem sie sich
                        entspannen und neue Energie tanken können.
                      </p>
                    )}
                    <p className="signature">Tomáš a Viktoria</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
          {/*{lang === "cs" ? (
            <Container>
              <div className="akce-vecery">
                <Col align="center">
                  <h2 className="m-0">VÁNOČNÍ MENU</h2>
                  <p className="text-center">24.12. - 26.12.2024</p>
                  <p>
                    Vánoce se blíží a s nimi i čas pro kouzelné chvíle strávené
                    s blízkými. Restaurace Edelweiss vás zve na nezapomenutelnou
                    sváteční večeři. Těšte se na tradiční vánoční pokrmy v
                    moderním pojetí a příjemnou atmosféru.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/VANOCNI_web.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                      MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          ) : lang === "en" ? (
            <Container>
              <div className="akce-vecery">
                <Col align="center">
                  <h2 className="m-0">CHRISTMAS MENU</h2>
                  <p className="text-center">24.12. - 26.12.2024</p>
                  <p>
                    Christmas is coming and with it, a time for magical moments
                    spent with loved ones. Restaurant Edelweiss invites you to
                    an unforgettable holiday dinner. Look forward to traditional
                    Christmas dishes with a modern twist and a pleasant
                    atmosphere.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/VANOCNI_web.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                      MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          ) : (
            <Container>
              <div className="akce-vecery">
                <Col align="center">
                  <h2 className="m-0">WEIHNACHTSMENÜ</h2>
                  <p className="text-center">24.12. - 26.12.2024</p>
                  <p>
                    Weihnachten steht vor der Tür und damit die Zeit für
                    zauberhafte Momente mit Ihren Liebsten. Das Restaurant
                    Edelweiss lädt Sie zu einem unvergesslichen festlichen
                    Abendessen ein. Freuen Sie sich auf traditionelle
                    Weihnachtsgerichte in moderner Interpretation und eine
                    angenehme Atmosphäre.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/VANOCNI_web.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                      MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          )}*/}
          {/*
          {lang === "cs" ? (
            <Container>
              <div className="akce-vecery-sil">
                <Col align="center">
                  <h2 className="m-0">SVATOMARTINSKÉ MENU</h2>
                  <p className="text-center">09. - 11.11.2024</p>
                  <p>
                    Ochutnejte pravou svatomartinskou klasiku!<br></br>
                    Připravili jsme pro Vás speciální menu z těch
                    nejkvalitnějších surovin. Křupavá husa, domácí knedlíky,
                    červené zelí a mladé víno – to vše v jedinečné kombinaci.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/svatomartinske_menu_2024.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                      MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          ) : lang === "de" ? (
            <Container>
              <div className="akce-vecery-sil">
                <Col align="center">
                  <h2 className="m-0">St. Martin's Day menu</h2>
                  <p className="text-center">09. - 11.11.2024</p>
                  <p>
                    Indulge in the traditional flavors of St. Martin's Day.
                    <br></br>
                    Our specially crafted menu showcases the finest ingredients.
                    Savor the delectable combination of crispy goose, homemade
                    dumplings, red cabbage, and young wine.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/svatomartinske_menu_2024.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                    MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          ) : (
            <Container>
              <div className="akce-vecery-sil">
                <Col align="center">
                  <h2 className="m-0">Martinsgansl-Menü</h2>
                  <p className="text-center">09. - 11.11.2024</p>
                  <p>
                    Lassen Sie sich von den traditionellen Aromen des
                    Martinstags verführen. <br></br>
                    Unser sorgfältig zusammengestelltes Menü präsentiert die
                    feinsten Zutaten. Genießen Sie die köstliche Kombination aus
                    knuspriger Gans, hausgemachten Knödeln, Rotkohl und jungem
                    Wein.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/svatomartinske_menu_2024.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                    MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          )}*/}
          {/*
          {lang === "cs" ? (
            <Container>
              <div className="akce-vecery">
                <Col align="center">
                  <h2 className="m-0">VALENTÝNSKÉ MENU</h2>
                  <p className="text-center">14.02.2024</p>
                  <p>
                    Přijďte rozzářit svá srdce a dejte si naše Valentýnské menu,
                    neboť staré rčení praví, že láska prochází žaludkem a my v
                    Hotel Edelweiss věříme, že je to pravda.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/valentyn_menu.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                      MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          ) : lang === "de" ? (
            <Container>
              <div className="akce-vecery">
                <Col align="center">
                  <h2 className="m-0">VALENTINSMENÜ</h2>
                  <p className="text-center">14.02.2024</p>
                  <p>
                    Kommen Sie und bringen Sie Ihre Herzen zum Leuchten und
                    genießen Sie das Valentinstagsmenü, denn das alte
                    Sprichwort, dass Liebe durch den Magen geht, ist wahr und
                    wir im Hotel Edelweiss glauben an diese Weisheit.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/valentyn_menu.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                      MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          ) : (
            <Container>
              <div className="akce-vecery">
                <Col align="center">
                  <h2 className="m-0">VALENTINE'S MENU</h2>
                  <p className="text-center">14.02.2024</p>
                  <p>
                    Come and light up your hearts and enjoy the Valentine's Day
                    menu, for the old saying is true that love goes through the
                    stomach and we in Hotel Edelweiss believe it to be true.
                  </p>
                </Col>
                <Col align="center">
                  <a
                    href="https://hoteledelweiss.cz/Menu/valentyn_menu.pdf"
                    target="_blank"
                    className="icon-text"
                  >
                    <button className="rest-button valentine mt-0 my-sm-2">
                      MENU
                    </button>
                  </a>
                </Col>
              </div>
            </Container>
          )}*/}
          <div className="cards container-lg">
            <div className="card-content">
              <Row className="mt-1 mb-1 mb-md-3 text-center">
                <Col lg={4} className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-3">
                    <img
                      src={deluxe}
                      alt="DeLuxe pokoj Hotel Edelweiss Liberec"
                    />
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>DELUXE</h4>
                      </CardTitle>
                    </CardBody>
                    <CardBody className="d-flex flex-column">
                      <CardText>
                        {lang === "cs" ? (
                          <p>
                            Největší hotelové dvoulůžkové pokoje
                            o&nbsp;velikosti 49&nbsp;m<sup>2</sup>{" "}
                            s&nbsp;vlastní terasou s&nbsp;posezením
                            a&nbsp;jedinečným výhledem na Ještěd či Liberec mají
                            svoji neopakovatelnou atmosféru. Díky své velikosti
                            nabízejí možnost přistýlky a&nbsp;jsou tak ideální
                            volbou při cestování s&nbsp;dětmi, nebo pro
                            náročnější hosty, kteří chtějí mít víc prostoru.
                            Součástí pokoje jsou také křesílka
                            a&nbsp;konferenční stolek.
                          </p>
                        ) : lang === "en" ? (
                          <p>
                            The hotel's largest double rooms measuring 49&nbsp;m
                            <sup>2</sup> with&nbsp;their terrace
                            with&nbsp;seating and&nbsp;a&nbsp;unique view of
                            Ještěd or Liberec have their unique atmosphere.
                            Thanks to their size, they offer the&nbsp;option of
                            an&nbsp;extra bed and&nbsp;are thus an ideal choice
                            when traveling with children or for more demanding
                            guests who want more space. The room also includes
                            armchairs and&nbsp;a&nbsp;coffee table.
                          </p>
                        ) : (
                          <p>
                            Die größten Doppelzimmer des Hotels mit einer Größe
                            von 49&nbsp;m<sup>2</sup> mit eigener Terrasse mit
                            Sitzgelegenheiten und einem einzigartigen Blick auf
                            Ještěd oder Liberec haben eine einzigartige
                            Atmosphäre. Dank ihrer Größe bieten sie die
                            Möglichkeit einer Aufbettung und sind somit eine
                            ideale Wahl für Reisen mit Kindern oder für
                            anspruchsvollere Gäste, die mehr Platz wünschen. Das
                            Zimmer ist außerdem mit Sesseln und einem Couchtisch
                            ausgestattet.
                          </p>
                        )}
                      </CardText>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/hotel">
                        <button className="card-button">
                          {lang === "cs"
                            ? "REZERVOVAT"
                            : lang === "en"
                            ? "RESERVE"
                            : "MEHR"}
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg={4} className="d-flex align-items-stretch">
                  <Card className="card-content-normal-white mt-3 mt-md-5 mb-lg-3">
                    <img
                      src={superior}
                      alt="Superior pokoj Hotel Edelweiss Liberec"
                    />
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4 className="white-card">SUPERIOR</h4>
                      </CardTitle>
                    </CardBody>
                    <CardBody className="d-flex flex-column">
                      <CardText>
                        {lang === "cs" ? (
                          <p className="white-card">
                            Komfortní dvoulůžkové pokoje o&nbsp;velikosti
                            25&nbsp;m<sup>2</sup> s&nbsp;jedinečným výhledem na
                            Ještěd či Liberec nabízí vysoký standard pro pohodlí
                            svých hostů. Díky své velikosti nabízejí možnost
                            přistýlky a&nbsp;jsou tak ideální volbou při
                            cestování s&nbsp;dětmi.
                          </p>
                        ) : lang === "en" ? (
                          <p className="white-card">
                            Comfortable double rooms with a&nbsp;size of
                            25&nbsp;m<sup>2</sup> with&nbsp;a&nbsp;unique view
                            of Ještěd or Liberec offer a high standard for
                            the&nbsp;comfort of its guests. Thanks to their
                            size, they offer the&nbsp;option of an&nbsp;extra
                            bed and&nbsp;are thus an&nbsp;ideal choice when
                            traveling with&nbsp;children.
                          </p>
                        ) : (
                          <p className="white-card">
                            Komfortable Doppelzimmer mit einer Größe von
                            25&nbsp;m<sup>2</sup> mit einzigartigem Blick auf
                            Ještěd oder Liberec bieten einen hohen Standard für
                            den Komfort seiner Gäste. Dank ihrer Größe bieten
                            sie die Möglichkeit einer Aufbettung und sind somit
                            eine ideale Wahl für Reisen mit Kindern.
                          </p>
                        )}
                      </CardText>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/hotel">
                        <button className="rest-button">
                          {lang === "cs"
                            ? "REZERVOVAT"
                            : lang === "en"
                            ? "RESERVE"
                            : "MEHR"}
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col lg={4} className="d-flex align-items-stretch">
                  <Card className="card-content-normal mt-3 mt-md-5 mb-lg-3">
                    <img
                      src={standard}
                      alt="Standard pokoj Hotel Edelweiss Liberec"
                    />
                    <CardBody className="text-center">
                      <CardTitle>
                        <h4>STANDARD</h4>
                      </CardTitle>
                    </CardBody>
                    <CardBody className="d-flex flex-column">
                      <CardText>
                        {lang === "cs" ? (
                          <p>
                            Pohodlné dvoulůžkové pokoje o velikosti 18&nbsp;m
                            <sup>2</sup> s&nbsp;koupelnou a&nbsp;sprchovým
                            koutem, pracovním stolkem a&nbsp;minibarem.
                            V&nbsp;tomto pokoji najdou hosté veškeré pohodlí ve
                            dvou pro svůj čas odpočinku a&nbsp;relaxace.
                          </p>
                        ) : lang === "en" ? (
                          <p>
                            Comfortable double rooms with a&nbsp;size of
                            18&nbsp;m<sup>2</sup> with&nbsp;bathroom
                            and&nbsp;shower, work desk, and&nbsp;minibar.
                            In&nbsp;this room, guests will find all
                            the&nbsp;comforts of two for their time of
                            relaxation.
                          </p>
                        ) : (
                          <p>
                            Komfortable Doppelzimmer mit einer Größe von
                            18&nbsp;m<sup>2</sup> mit Bad und Dusche,
                            Schreibtisch und Minibar. In diesem Zimmer finden
                            die Gäste allen Komfort von zwei für ihre Zeit der
                            Ruhe und Entspannung.
                          </p>
                        )}
                      </CardText>
                    </CardBody>
                    <div className="card-footer-btn">
                      <Link className="icon-text" to="/hotel">
                        <button className="card-button">
                          {lang === "cs"
                            ? "REZERVOVAT"
                            : lang === "en"
                            ? "RESERVE"
                            : "MEHR"}
                        </button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <Container fluid className="well-rest">
            <Row>
              <Col md={6} className="p-0 well-rest-card">
                <div className="restaurant-text">
                  <h3>
                    {lang === "cs"
                      ? "RESTAURACE"
                      : lang === "en"
                      ? "RESTAURANT"
                      : "RESTAURANT"}
                    <span></span>
                  </h3>
                  {lang === "cs" ? (
                    <>
                      <p>
                        Hotelová restaurace uspokojí náročné chutě každého, kdo
                        má rád českou kuchyni v&nbsp;moderním pojetí. Pod
                        taktovkou šéfkuchaře si hosté vychutnají pestré zdravé,
                        a&nbsp;přitom plnohodnotné snídaně, klasické obědy
                        i&nbsp;moderně připravenou českou večeři.
                      </p>
                      <p>
                        Restaurace pro třicet osob mohou navštívit i&nbsp;hosté
                        z&nbsp;venku. Náš tým rád připraví také obchodní
                        setkání, rodinné oslavy, svatby či firemní
                        teambuildingy.
                      </p>
                    </>
                  ) : lang === "en" ? (
                    <>
                      <p>
                        The hotel restaurant will satisfy the&nbsp;demanding
                        tastes of anyone who likes Czech cuisine
                        in&nbsp;a&nbsp;modern concept. Under the&nbsp;baton
                        of&nbsp;the&nbsp;chef, guests can enjoy a&nbsp;varied,
                        healthy, yet full breakfast, classic lunches,
                        and&nbsp;a&nbsp;modern Czech dinner.
                      </p>
                      <p>
                        The restaurant for&nbsp;thirty people can also be
                        visited by guests from&nbsp;outside. Our team will also
                        be happy to prepare for business meetings, family
                        celebrations, weddings, or corporate teambuilding.
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Das Hotelrestaurant befriedigt die anspruchsvollen
                        Geschmäcker aller Liebhaber der tschechischen Küche in
                        einem modernen Konzept. Unter der Leitung des
                        Küchenchefs können die Gäste ein abwechslungsreiches,
                        gesundes und dennoch vollmundiges Frühstück, klassische
                        Mittagessen und ein modernes tschechisches Abendessen
                        genießen.
                      </p>
                      <p>
                        Das Restaurant für dreißig Personen kann auch von
                        externen Gästen besucht werden. Unser Team bereitet auch
                        gerne Geschäftstreffen, Familienfeiern, Hochzeiten oder
                        Firmen-Teambuildings vor.
                      </p>
                    </>
                  )}
                  <div className="well-rest-footer">
                    <Link className="icon-text" to="/restaurant">
                      <button className="rest-button">MENU</button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={6} className="p-0 m-0">
                <img
                  src={restaurant}
                  alt="Restaurace - české poctivé jídlo"
                  className="restaurant-img"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="p-0 m-0 order-5 order-md-1">
                <img
                  src={wellness}
                  alt="Wellness pro odpočinek na cestách a na dovolené"
                  className="wellness-img"
                />
              </Col>
              <Col
                md={6}
                className="p-0 well-rest-card green order-1 order-md-5"
              >
                <div className="wellness-text">
                  <h3>WELLNESS</h3>
                  {lang === "cs" ? (
                    <>
                      <p>
                        Moderní wellness jsme nyní otevřeli! Přijďte si užít
                        naše nové moderní wellness centrum s masážemi, které
                        jsou k&nbsp;dispozici nejen pro hotelové hosty.
                      </p>
                      <p>
                        Do světa relaxace a&nbsp;odpočinku přenesou návštěvníky
                        hotelu maséři. K&nbsp;využití je jacuzzi, finská sauna,
                        infrasauna a&nbsp;odpočívárna&nbsp;+&nbsp;hotelové
                        služby.
                      </p>
                    </>
                  ) : lang === "en" ? (
                    <p>
                      Modern wellness for our guests soon! We are currently
                      working on&nbsp;the&nbsp;preparation of&nbsp;a&nbsp;modern
                      wellness center that will be available not only
                      to&nbsp;hotel guests. Masseurs will transport hotel
                      visitors to a&nbsp;world of relaxation. There will be
                      a&nbsp;jacuzzi, a&nbsp;Finnish sauna, an&nbsp;infrared
                      sauna, and a&nbsp;rest room + hotel services.
                    </p>
                  ) : (
                    <p>
                      Bald moderne Wellness für unsere Gäste! Wir arbeiten
                      derzeit an der Vorbereitung eines modernen
                      Wellnesscenters, das nicht nur Hotelgästen zur Verfügung
                      stehen wird. Masseure entführen die Hotelgäste in die Welt
                      der Entspannung und Erholung. Es wird einen Whirlpool,
                      eine finnische Sauna, eine Infrarotsauna und einen
                      Ruheraum + Hotelservice geben.
                    </p>
                  )}

                  <div className="well-rest-footer">
                    <Link className="icon-text" to="/wellness">
                      <button className="card-button">
                        {lang === "cs"
                          ? "VÍCE"
                          : lang === "en"
                          ? "MORE"
                          : "MEHR"}
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="p-0 well-rest-card">
                <div className="restaurant-text">
                  <h3>
                    {lang === "cs"
                      ? "GERMITEC DEZINFEKCE"
                      : lang === "en"
                      ? "GERMITEC DESINFECTION"
                      : "GERMITEC DESINFEKTION"}
                  </h3>
                  {lang === "cs" ? (
                    <p>
                      Restauraci, pokoje i&nbsp;všechny další prostory Wellness
                      hotelu Edelweiss pravidelně čistíme unikátní českou
                      dezinfekční technologií Germitec, která pomocí speciálního
                      světla dokonale ničí nežádoucí mikroorganismy
                      i&nbsp;v&nbsp;běžně nepřístupných nebo špatně přístupných
                      místech. Technologie vyvinutá ve spolupráci s&nbsp;českými
                      univerzitami pro využití v&nbsp;nemocnicích vám u&nbsp;nás
                      zaručí perfektní čistotu a&nbsp;skutečně zdravý pobyt.
                    </p>
                  ) : lang === "en" ? (
                    <p>
                      We regularly clean the restaurant, rooms, and all other
                      areas of&nbsp;the Wellness Hotel Edelweiss using
                      the&nbsp;unique Czech Germitec disinfection technology,
                      which uses a&nbsp;special light to perfectly destroy
                      unwanted microorganisms even in&nbsp;normally inaccessible
                      or poorly accessible places. Technology developed
                      in&nbsp;cooperation with Czech universities for use
                      in&nbsp;hospitals will guarantee you perfect cleanliness
                      and&nbsp;a&nbsp;truly healthy stay with us.
                    </p>
                  ) : (
                    <p>
                      Wir reinigen das Restaurant, die Zimmer und alle anderen
                      Bereiche des Wellnesshotels Edelweiss regelmäßig mit der
                      einzigartigen tschechischen
                      Germitec-Desinfektionstechnologie, die mithilfe eines
                      speziellen Lichts unerwünschte Mikroorganismen auch an
                      normalerweise unzugänglichen oder schlecht zugänglichen
                      Stellen perfekt zerstört. Technologie, die in
                      Zusammenarbeit mit tschechischen Universitäten für den
                      Einsatz in Krankenhäusern entwickelt wurde, garantiert
                      Ihnen perfekte Sauberkeit und einen wirklich gesunden
                      Aufenthalt bei uns.
                    </p>
                  )}

                  <div className="well-rest-footer">
                    <a
                      className="icon-text"
                      href="https://www.germitec.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="card-button">
                        {lang === "cs"
                          ? "VÍCE"
                          : lang === "en"
                          ? "MORE"
                          : "MEHR"}
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} className="p-0 m-0">
                <img
                  src={Germitec}
                  alt="author: https://www.germitec.cz/"
                  className="restaurant-img"
                />
              </Col>
            </Row>
          </Container>
        </Layout>
      </Fade>
    </>
  );
};

export default Home;
